// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito&display=swap");

// Variables
@import "variables-dark";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

.ui-autocomplete {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #121212;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
  }
  
  .ui-autocomplete > li > div {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #f5f5f5;
    white-space: nowrap;
  }
  
  .ui-state-hover,
  .ui-state-active,
  .ui-state-focus {
    text-decoration: none;
    color: #f5f5f5;
    background-color: #000;
    cursor: pointer;
  }
  
  .ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  

@import "main";
@import "gridstyles";
@import "nav-scroller";
@import "icons";
@import "bottom_nav";
@import "sharestyles";
@import "player";
