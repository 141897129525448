// Body

$variable-prefix: p-;
$primary: #6610f2;
$dark: #f8f9fa;
$body-bg: #121212;

// Typography
$body-color: #e9ecef;
$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$link-decoration: none;
// $container-padding-x: 0.3rem !default;
$link-color: #fff;

//Header color
$header-footer-bg-color: #000;
$header-footer-color: #fff;

//Modal 
$modal-content-color: #fff;
$modal-content-bg: #000;
$modal-content-border-color: #121212;
$modal-header-border-color:  #121212;
$btn-close-color: #fff;


//Navs

$navbar-dark-color: #fff;
// $breadcrumb-padding-x: 0.5rem;
// $breadcrumb-padding-y: 0.5rem;
// $breadcrumb-margin-bottom: 0;
// $breadcrumb-bg: var(--#{$variable-prefix}body-bg);
// $breadcrumb-border-radius: 0.25rem;

//Section Titles

$section-title-border-color: #6610f2;
$section-title-bg-color: #6610f2;
$section-title-color: #fff;

//Bottom Nav mobile
$bottom-nav-height: 55px;
$bottom-nav-background: #212529;
$bottom-nav-color: $link-color;
$bottom-nav-link-hover-color: #000;

//Card-Styles
$card-bg: #212529;

//Grid Videos
$card-video-color: #f8f9fa;
$card-video-body-span-color: #dedede;
$card-video-badge-bg-color: rgba(0, 0, 0, 0.726);
$card-category-playlist-bg-color: rgba(0, 0, 0, 0.548);
$card-category-playlist-color: #fff;

//Videos List
$video-list-color: $link-color;
$video-list-bg-color: $body-bg;

//Video Tags

$tags-color: #055fd5;
$tags-hover-color: #6610f2;

$video-info-color: #adb5bd;
$pagination-bg: #212529;
$pagination-hover-bg: #212529;
$pagination-focus-bg: #000;
$pagination-disabled-bg: #000;

$form-file-button-bg:  $primary;
